module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.verizonspecials.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"brandTokens":["VZN","VZN-RESI","VZNB"],"mapiBrandToken":"VZN","siteName":"verizonspecials","alternateName":"verizonspecials.com","siteURL":"https://www.verizonspecials.com","defaultTitleTemplate":"","defaultPhone":"8556318020","phoneSymbol":"-","defaultPromoCode":"54447","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046076","vwoProjectID":"894940","datasetManagerAPIKey":"0c6fa03273f2437793724746838886de","analyticsId":"GTM-WMTM9H"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"verizonspecials","short_name":"verizonspecials","start_url":"/","background_color":"##FFC800","theme_color":"##FFC800","icon":"src/images/favicon.svg","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b4ea4b5431a1f0b3f8f9dee22f753b23"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
