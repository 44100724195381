import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const Dropdown = withTheme(() => (
  <Global
    styles={css`
      .dropdown-section {
        .leshen-variable-content {
          display: flex;
          justify-content: space-evenly;

          @media (max-width: 700px) {
            display: block;
          }
        }
      }

      .leshen-stack {
        @media (max-width: 768px) {
          margin-bottom: 30px;

          .dropdown {
            margin-top: 0 !important;
          }
        }
      }
    `}
  />
))

export default Dropdown
