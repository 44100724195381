import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      .leshen-typography-body {
        .leshen-link {
          color: #000000;
          font-size: 1.28571em;
          font-weight: 700;
          line-height: 1.4em;
          text-decoration: none;
        }

        .leshen-link:hover {
          color: #ee0000;
          text-decoration: underline;
        }
      }
    `}
  />
))

export default GlobalCss
