/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#F50A23',
    secondary: '#FF3C2D',
    tertiary: '#F3EDE0',
    dark: '#000000',
    white: '#FFFFFF',
    light: '#F6F6F6',
    warning: '#ff7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#F5FF1E',
    background2: '#FF3C2D',
    background3: '#F50A23',
    background4: '#F3EDE0',
    background5: '#F3EDE0',
    background6: '#F3EDE0',
  },
  gradients: {
    primary: 'linear-gradient(to right, #000000, #000000)',
    secondary: 'linear-gradient(to right, #000000, #000000)',
    tertiary: 'linear-gradient(to right, #000000, #000000)',
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: 'Verizon NHG Bold, sans-serif',
    },
    subheadings: {
      family: 'Verizon NHG Bold, sans-serif',
    },
    body: {
      family: 'Verizon NHG Regular, sans-serif',
    },
    secondary: {
      family: 'Verizon NHG Light, sans-serif',
    },
  },
  components: {
    Button: {
      rounded: 'max',
      border: '0',
      variants: {
        primary: {
          color: 'white',
        },
        secondary: {
          color: 'white',
        },
        tertiary: {
          color: 'dark',
        },
        dark: {
          color: 'white',
        },
        white: {
          color: 'dark',
        },
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '16px',
  backgroundColors: {
    dark: {
      textColor: 'white',
    },
    white: {
      textColor: 'dark',
    },
    primary: {
      textColor: 'white',
    },
    secondary: {
      textColor: 'white',
    },
    tertiary: {
      textColor: '#FFFFFF',
    },
  },
  PackageCard: {
    alignment: 'left',
    hideIcons: true,
    radius: '16px',
    backgroundColor: '#F3EDE0',
    color: '#F50A23',
    shadow: 'none',
    border: 'none',
    label: {
      inside: true,
      backgroundColor: '#F50A23',
      radius: '28px',
    },
  },
}
